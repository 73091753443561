// routes
import { PATH_AUTH, PATH_SERMONS, PATH_PAGE } from '../../routes/paths';
// components
import { PATH_AFTER_LOGIN } from '../../config';
// components
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22,
};

const menuConfig = [
  {
    title: 'Home',
    icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
    path: '/',
  },
  {
    title: 'Find out more',
    path: '/pages',
    icon: <Iconify icon={'eva:file-fill'} {...ICON_SIZE} />,
    children: [
      {
        subheader: 'About Us',
        items: [
          // { title: 'About us', path: PATH_PAGE.about },
          { title: 'Core Convictions', path: PATH_PAGE.aboutUs.coreConvictions },
          { title: 'Brief History of KEC', path: PATH_PAGE.aboutUs.briefHistory },
          { title: 'Who\'s who', path: PATH_PAGE.aboutUs.whosWho },
          { title: 'How to Give', path: PATH_PAGE.aboutUs.howToGive },
          { title: 'What We Sing', path: PATH_PAGE.aboutUs.whatWeSing },
          { title: 'Location / Contact Us', path: PATH_PAGE.aboutUs.location },
          // { title: 'Contact us', path: PATH_PAGE.aboutUs.contactUs },
          // { title: 'Contact us', path: PATH_PAGE.comingSoon },
        ],
      },
      {
        subheader: 'I\'m New',
        items: [
          { title: '...to Jesus', path: PATH_PAGE.imNew.toJesus },
          { title: '...to this church', path: PATH_PAGE.imNew.toChurch },
          // { title: 'Reset password', path: PATH_AUTH.resetPassword },
          // { title: 'Verify code', path: PATH_AUTH.verify },
        ],
      },
      {
        subheader: 'Connect',
        items: [
          { title: 'Christianity Explored', path: PATH_PAGE.connect.christianityExplored },
          { title: 'Home Fellowship Groups', path: PATH_PAGE.connect.homeFellowshipGroups },
          { title: 'Sunday School', path: PATH_PAGE.connect.sundaySchool },
          { title: 'English Youth Ministry', path: PATH_PAGE.connect.englishYouthMinistry },
          // { title: 'College and University Students (FOCUS)', path: PATH_PAGE.connect.focus },
          { title: 'Ladies Fellowship', path: PATH_PAGE.connect.ladiesFellowship },
          { title: 'Prayer', path: PATH_PAGE.connect.prayer },
          { title: 'Serving @ KEC', path: PATH_PAGE.connect.serving },

        ],
      },
      {
        subheader: 'Resources',
        items: [
          { title: 'Useful links', path: PATH_PAGE.resources.usefulLinks },
          { title: 'Recommended Reading', path: PATH_PAGE.resources.recommendedReading },
          { title: 'Wedding & Burial Policies', path: PATH_PAGE.resources.weddingPolicyGuidelines },
          // { title: 'Reset password', path: PATH_AUTH.resetPassword },
          // { title: 'Verify code', path: PATH_AUTH.verify },
        ],
      },
      // {
      //   subheader: 'Dashboard',
      //   items: [{ title: 'Dashboard', path: PATH_AFTER_LOGIN }],
      // },
    ],
  },
  {
    title: 'Sermons',
    icon: <Iconify icon={'eva:book-open-fill'} {...ICON_SIZE} />,
    path: PATH_SERMONS,
  },
];

export default menuConfig;
