// @mui
// import { useTheme } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

export default function MotivationIllustration({ ...other }: BoxProps) {
  // const theme = useTheme();
  // const PRIMARY_LIGHTER = theme.palette.primary.lighter;
  // const PRIMARY_MAIN = theme.palette.primary.main;
  // const PRIMARY_DARK = theme.palette.primary.dark;
  // const PRIMARY_DARKER = theme.palette.primary.darker;

  return <Box {...other} />;
}
