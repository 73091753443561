// ----------------------------------------------------------------------

export const fullName = [
  'Alex Kueh',
];

export const firstName = [
  'Alex',
];

export const lastName = [
  'Kueh',
];
