import { useLocation, Outlet } from 'react-router-dom';
// @mui
import { Box, Link, Container, Typography, Stack } from '@mui/material';
// components
import Logo from '../../components/Logo';
//
import MainFooter from './MainFooter';
import MainHeader from './MainHeader';
import moment from 'moment-mini';

// ----------------------------------------------------------------------

export default function MainLayout() {
  const { pathname } = useLocation();

  const isHome = pathname === '/';

  return (
    <Stack sx={{ minHeight: 1 }}>
      <MainHeader />

      <Outlet />

      {/* <Box sx={{ flexGrow: 1 }} /> */}

      {!isHome ? (
        <MainFooter />
      ) : (
        <></>
        // apparently causes CLS issues on loading. moved to HomeQuote >> HomeColorPresets
        // <Box
        //   sx={{
        //     py: 5,
        //     textAlign: 'center',
        //     position: 'relative',
        //     bgcolor: 'background.default',
        //   }}
        // >
        //   <Container>
        //     <Logo sx={{ mx: 'auto' }} />

        //     <Typography variant="caption" component="p">
        //       {/* © All rights reserved */}
        //       <br /> BEM KEC © &nbsp;
        //       {moment().year()}
        //     </Typography>
        //   </Container>
        // </Box>
      )}
    </Stack>
  );
}
