import { PlanFreeIcon, PlanStarterIcon, PlanPremiumIcon } from '../assets';

// ----------------------------------------------------------------------

const LICENSES = ['Standard', 'Standard Plus', 'Extended'];

export const _homePlans = [...Array(3)].map((_, index) => ({
  license: LICENSES[index],
  commons: ['One end products', '12 months updates', '6 months of support'],
  options: [
    'JavaScript version',
    'TypeScript version',
    'Design Resources',
    'Commercial applications',
  ],
  icons: [
    'https://minimal-assets-api.vercel.app/assets/images/home/ic_sketch.svg',
    'https://minimal-assets-api.vercel.app/assets/images/home/ic_figma.svg',
    'https://minimal-assets-api.vercel.app/assets/images/home/ic_js.svg',
    'https://minimal-assets-api.vercel.app/assets/images/home/ic_ts.svg',
  ],
}));

export const _homeTitles = [
  {
    title: 'Checking our church out?',
    icon: <PlanFreeIcon />,
    href: '/new-to-church',
    caption: '',
    image:
      'https://res.cloudinary.com/dscrdg0ou/image/upload/v1685714650/bemkec/bemkec_front_y5shoa.png',
    price: 0,
    lists: [
      { text: 'Want to find out where we’re located?' },
      { text: 'What should you expect in our Sunday Services?' },
    ],
    labelAction: 'Find out more',
  },
  {
    title: 'Want to learn more about Jesus?',
    icon: <PlanStarterIcon />,
    href: '/new-to-jesus',
    price: 0,
    caption: '',
    image: 'https://res.cloudinary.com/dscrdg0ou/image/upload/v1688048173/bemkec/crossbg_dbyhho.jpg',
    lists: [
      { text: ' How do we know there’s a God?' },
      { text: 'Why is the world so messed up?' },
      { text: ' Why does Jesus matter?' },
    ],
    labelAction: 'Find out more',
  },
  {
    title: 'Sermons',
    icon: <PlanPremiumIcon />,
    href: 'https://sermons.bemkec.my',
    price: 0,
    caption: '',
    image: 'https://res.cloudinary.com/dscrdg0ou/image/upload/v1688048172/bemkec/sermon_qjjpva.jpg',
    lists: [
      { text: '' },
      { text: '' },
      { text: '' },
      { text: 'Weekly sermons available here' },
    ],
    labelAction: 'Click here to listen',
  },
];
