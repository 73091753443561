import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// guards
import useAuth from '../hooks/useAuth';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes('/dashboard') && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        // {
        //   path: 'register',
        //   element: (
        //     <GuestGuard>
        //       <Register />
        //     </GuestGuard>
        //   ),
        // },
        // { path: 'login-unprotected', element: <Login /> },
        // { path: 'register-unprotected', element: <Register /> },
        // { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralApp /> },
        { path: 'ecommerce', element: <GeneralEcommerce /> },
        { path: 'analytics', element: <GeneralAnalytics /> },
        { path: 'banking', element: <GeneralBanking /> },
        { path: 'booking', element: <GeneralBooking /> },

        {
          path: 'e-commerce',
          children: [
            { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
            { path: 'shop', element: <EcommerceShop /> },
            { path: 'product/:name', element: <EcommerceProductDetails /> },
            { path: 'list', element: <EcommerceProductList /> },
            { path: 'product/new', element: <EcommerceProductCreate /> },
            { path: 'product/:name/edit', element: <EcommerceProductCreate /> },
            { path: 'checkout', element: <EcommerceCheckout /> },
            { path: 'invoice', element: <EcommerceInvoice /> },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'profile', element: <UserProfile /> },
            { path: 'cards', element: <UserCards /> },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':name/edit', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> },
          ],
        },
        {
          path: 'blog',
          children: [
            { element: <Navigate to="/dashboard/blog/posts" replace />, index: true },
            { path: 'posts', element: <BlogPosts /> },
            { path: 'post/:title', element: <BlogPost /> },
            { path: 'new-post', element: <BlogNewPost /> },
            { path: 'edit-post/:title', element: <BlogEditPost /> },
          ],
        },
        {
          path: 'mail',
          children: [
            { element: <Navigate to="/dashboard/mail/all" replace />, index: true },
            { path: 'label/:customLabel', element: <Mail /> },
            { path: 'label/:customLabel/:mailId', element: <Mail /> },
            { path: ':systemLabel', element: <Mail /> },
            { path: ':systemLabel/:mailId', element: <Mail /> },
          ],
        },
        {
          path: 'chat',
          children: [
            { element: <Chat />, index: true },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> },
          ],
        },
        { path: 'calendar', element: <Calendar /> },
        { path: 'kanban', element: <Kanban /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        { path: 'about-us', element: <About /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'faqs', element: <Faqs /> },
        { path: 'posts', element: <DashboardPosts /> },
        { path: 'posts/:title', element: <DashboardPost /> },
        { path: 'core-convictions', element: <CoreConvictions /> },
        { path: 'brief-history', element: <BriefHistory /> },
        { path: 'whos-who', element: <WhosWho /> },
        { path: 'how-to-give', element: <HowToGive /> },
        { path: 'location', element: <Location /> },
        { path: 'contact-us', element: <ContactUs /> },
        { path: 'what-we-sing', element: <WhatWeSing /> },
        { path: 'new-to-jesus', element: <ToJesus /> },
        { path: 'new-to-church', element: <ToChurch /> },
        { path: 'christianity-explored', element: <ChristianityExplored /> },
        { path: 'home-fellowship-groups', element: <HomeFellowshipGroups /> },
        { path: 'what-we-believe', element: <WhatWeBelieve /> },
        { path: 'home-fellowship-groups', element: <HomeFellowshipGroups /> },
        { path: 'sunday-school', element: <SundaySchool /> },
        { path: 'english-youth-ministry', element: <EnglishYouthMinistry /> },
        // { path: 'focus', element: <FOCUS />},
        { path: 'focus', element: <Page500 /> },
        { path: 'ladies-fellowship', element: <LadiesFellowship /> },
        { path: 'prayer', element: <Prayer/>},
        { path: 'serving', element: <Serving />},
        { path: 'useful-links', element: <UsefulLinks /> },
        { path: 'recommended-reading', element: <RecommendedReading /> },
        { path: 'wedding-policy-guidelines', element: <WeddingPolicyGuidelines /> },
        { path: 'privacy-policy', element: <PrivacyPolicy />}
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
// const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));
const EcommerceShop = Loadable(lazy(() => import('../pages/dashboard/EcommerceShop')));
const EcommerceProductDetails = Loadable(
  lazy(() => import('../pages/dashboard/EcommerceProductDetails'))
);
const EcommerceProductList = Loadable(
  lazy(() => import('../pages/dashboard/EcommerceProductList'))
);
const EcommerceProductCreate = Loadable(
  lazy(() => import('../pages/dashboard/EcommerceProductCreate'))
);
const EcommerceCheckout = Loadable(lazy(() => import('../pages/dashboard/EcommerceCheckout')));
const EcommerceInvoice = Loadable(lazy(() => import('../pages/dashboard/EcommerceInvoice')));
const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));
const BlogEditPost = Loadable(lazy(() => import('../pages/dashboard/BlogEditPost')));
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));
// Main
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));

//about us
const CoreConvictions = Loadable(lazy(() => import('../pages/about/CoreConvictions')));
const WhatWeBelieve = Loadable(lazy(() => import('../pages/about/WhatWeBelieve')));
const BriefHistory = Loadable(lazy(() => import('../pages/about/BriefHistory')));
const WhosWho = Loadable(lazy(() => import('../pages/about/WhosWho')));
const HowToGive = Loadable(lazy(() => import('../pages/about/HowToGive')));
const Location = Loadable(lazy(() => import('../pages/about/Location')));
const ContactUs = Loadable(lazy(() => import('../pages/about/ContactUs')));
const WhatWeSing = Loadable(lazy(() => import('../pages/about/WhatWeSing')));

//i'm new
const ToJesus = Loadable(lazy(() => import('../pages/im-new/ToJesus')));
const ToChurch = Loadable(lazy(() => import('../pages/im-new/ToChurch')));

//connect
const ChristianityExplored = Loadable(lazy(() => import('../pages/connect/ChristianityExplored')));
const HomeFellowshipGroups = Loadable(lazy(() => import('../pages/connect/HomeFellowshipGroups')));
const SundaySchool = Loadable(lazy(() => import('../pages/connect/SundaySchool')));
const EnglishYouthMinistry = Loadable(lazy(() => import('../pages/connect/EnglishYouthMinistry')));
const FOCUS = Loadable(lazy(() => import('../pages/connect/FOCUS')));
const LadiesFellowship = Loadable(lazy(() => import('../pages/connect/LadiesFellowship')));
const Prayer = Loadable(lazy(() => import('../pages/connect/Prayer')));
const Serving = Loadable(lazy(() => import('../pages/connect/Serving')));
//resources
const UsefulLinks = Loadable(lazy(() => import('../pages/resources/UsefulLinks')));
const RecommendedReading = Loadable(lazy(() => import('../pages/resources/RecommendedReading')));
const WeddingPolicyGuidelines = Loadable(
  lazy(() => import('../pages/resources/WeddingAndBurialPolicies'))
);
const PrivacyPolicy = Loadable(
  lazy(() => import('../pages/resources/PrivacyPolicy'))
);

const DashboardPosts = Loadable(lazy(() => import('../pages/LandingPosts')));
const DashboardPost = Loadable(lazy(() => import('../pages/LandingPost')));
