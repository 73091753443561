// ----------------------------------------------------------------------

export const title = [
  'Centred on the gospel of Jesus Christ',
  'Driven by God’s Word',
  'Focused on growing disciples',
  'Being a community of truth & love',
];

export const sentence = [
  'Assumenda nam repudiandae rerum fugiat vel maxime.',
];

export const description = [
  'The gospel is the good news that through the life, death and resurrection of Jesus Christ, all those who trust in him will enjoy forgiveness of sin and be part of the kingdom he establishes. \n \n We acknowledge that the gospel is never just the minimum standard of doctrinal content. The gospel is not to be understood simply as being for non-Christians only, or as the ABCs of the Christian life, to be left behind as Christians become more “advanced”. \n \n Instead, we acknowledge that the gospel must remain central to all Christians (1 Cor. 15:3). It is not only the power for salvation (Romans 1:16), but it is also the gospel that sanctifies. As we deepen our grasp of the gospel that unites us to Christ, and understand its radical implications, we are brought to maturity, empowered for ministry, and compelled to go on mission. (Gal. 2:14; Col. 1:6, 28; 2:6-7). The gospel is therefore the A-Z of the Christian life. \n \n Consequently, we must continually strive to remind, clarify and teach what the gospel about Jesus Christ is (2 Peter 1:12-15). Our entire teaching and church ministry must consistently draw the connections between the gospel and issues of worldview, conduct and so on. To say the gospel is central is not to deny the need to listen to the full counsel of God, but to recognise that when we assume the gospel, we are in grave danger of failing to pass down the gospel to the next generation.',
  'God’s gospel is ultimately to be heard in God’s Word. All of the Scriptures ultimately point to Jesus (Luke 24:44-47), and so to confess the functional centrality of the gospel is to drive us back to the Word, so that the Word will drive everything we do. It is also an implication of our commitment to the sufficiency of Scripture, as God’s appointed way of converting and growing his people (2 Tim. 3:14-17, 1 Peter 1:23-2:2). \n \n This means that in practice, we are committed to systematic expository preaching as the main diet of the church (Acts 20:26; 2 Timothy 4:2). This ensures the whole counsel of God is properly heard and the full range of God’s concerns and priorities are discovered. It also means we aim to make sure the Bible, rightly handled, shapes our small groups, Sunday School, youth ministries and various other programmes.',
  'Over and over again, the New Testament presents us with the reason for our redemption, that we are saved to become holy and mature in Christ (Rom. 8:29; Eph. 1:3-4, 2:8-10; Col. 1:28; 2 Tim. 1:8-9). We are called to make efforts to pursue Christlikeness (Phil. 2:12-13), as well as to prioritise gospel concerns (Phil. 1:12-14, 21-26). Nonetheless, we never do this apart from dependence on Christ and the work of his Spirit (Rom. 8:9-13; Col 3:1-5). God’s grace is precisely what is needed to teach us to live in a manner consistent with the gospel (Titus 2:11-15), as we keep in step with his Spirit (Gal. 5:16-26), and pursue the habits of grace such as Bible reading, prayer, and community.',
  'God has appointed Christian community as his means for spurring one another on to love and good deeds (Heb. 10:24-25). As such, as we regularly meet together, we want to be speaking the truth in love to one another (Eph. 4:15), encouraging one another and carrying each other’s burdens, loving one another and being willing to challenge one another (Rom. 12:10; Gal. 6:2, 10), whether formally or informally. We want to stress partnership in the gospel, to strive side by side and to stand firm as one for the sake of the gospel, and to keep putting each other’s needs first in humility (Phil. 1:27-2:11).',
];

export const href = [
  'https://www.youtube.com/embed/umHUfnzn9Gs',
  'https://www.youtube.com/embed/S-DzxbZ7EEQ',
  'https://www.youtube.com/embed/b1E4ChAlJcU',
  'https://www.youtube.com/embed/C_pcK9LOuLU',
]