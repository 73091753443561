import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Grid, Link, Divider, Container, Typography, Stack } from '@mui/material';
// routes
import { PATH_PAGE } from '../../routes/paths';
// components
import Logo from '../../components/Logo';
import SocialsButton from '../../components/SocialsButton';
import moment from 'moment-mini';

// ----------------------------------------------------------------------

const LINKS = [
  {
    headline: 'About Us',
    children: [
      { name: 'Core Convictions', href: PATH_PAGE.aboutUs.coreConvictions },
      { name: 'What We Believe', href: PATH_PAGE.aboutUs.whatWeBelieve },
      { name: 'Brief History of KEC', href: PATH_PAGE.aboutUs.briefHistory },
      { name: "Who's who", href: PATH_PAGE.aboutUs.whosWho },
      { name: 'How to Give', href: PATH_PAGE.aboutUs.howToGive },
      { name: 'Location / Contact Us', href: PATH_PAGE.aboutUs.location },
      // { name: 'Contact us', href: PATH_PAGE.aboutUs.contactUs },
    ],
  },
  {
    headline: "I'm NEW",
    children: [
      { name: '...to Jesus', href: PATH_PAGE.imNew.toJesus },
      { name: '...to this church', href: PATH_PAGE.imNew.toChurch },
    ],
  },
  {
    headline: 'Connect',
    children: [
      { name: 'Christianity Explored', href: PATH_PAGE.connect.christianityExplored },
      { name: 'Home Fellowship Groups', href: PATH_PAGE.connect.homeFellowshipGroups },
      { name: 'Sunday School', href: PATH_PAGE.connect.sundaySchool },
      { name: 'English Youth Ministry', href: PATH_PAGE.connect.englishYouthMinistry },
      // { name: 'College and University Students (FOCUS)', href: PATH_PAGE.connect.focus },
      { name: 'Ladies Fellowship', href: PATH_PAGE.connect.ladiesFellowship },
      { name: 'Prayer', href: PATH_PAGE.connect.prayer },
      { name: 'Serving @ KEC', href: PATH_PAGE.connect.serving },
    ],
  },
  {
    headline: 'Resources',
    children: [
      { name: 'Useful links', href: PATH_PAGE.resources.usefulLinks },
      { name: 'Recommended Reading', href: PATH_PAGE.resources.recommendedReading },
      { name: 'Wedding Policy & guidelines', href: PATH_PAGE.resources.weddingPolicyGuidelines },
    ],
  },
];

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  return (
    <RootStyle>
      <Divider />
      <Container sx={{ pt: 10 }}>
        <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          <Grid item xs={12} sx={{ mb: 3 }}>
            <Logo sx={{ mx: { xs: 'auto', md: 'inherit' } }} />
          </Grid>
          <Grid item xs={8} md={3}>
            <Typography variant="body2" sx={{ pr: { md: 5 } }}>
              We want to be centered on the gospel of Jesus Christ, Driven by God’s Word, Focused on
              growing disciples, Being a community of truth & love
            </Typography>

            <Stack
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              sx={{ mt: 5, mb: { xs: 5, md: 0 } }}
            >
              <SocialsButton sx={{ mx: 0.5 }} />
            </Stack>
          </Grid>

          <Grid item xs={12} md={8}>
            <Stack
              spacing={5}
              direction={{ xs: 'column', md: 'row' }}
              justifyContent="space-between"
            >
              {LINKS.map((list) => (
                <Stack key={list.headline} spacing={2}>
                  <Typography component="p" variant="overline">
                    {list.headline}
                  </Typography>
                  {list.children.map((link) => (
                    <Link
                      to={link.href}
                      key={link.name}
                      color="inherit"
                      variant="body2"
                      component={RouterLink}
                      sx={{ display: 'block' }}
                    >
                      {link.name}
                    </Link>
                  ))}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>

        <Typography
          component="p"
          variant="body2"
          sx={{
            mt: 10,
            pb: 5,
            fontSize: 13,
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          ©  {moment().year()} BEM KEC
        </Typography>
      </Container>
    </RootStyle>
  );
}
